<template>
  <video-player
    class="video-player-box"
    ref="videoPlayer"
    :options="options"
    :playsinline="true"
    customEventName="customstatechangedeventname"
    @play="onPlayerPlay($event)"
    @pause="onPlayerPause($event)"
    @ended="onPlayerEnded($event)"
    @waiting="onPlayerWaiting($event)"
    @playing="onPlayerPlaying($event)"
    @loadeddata="onPlayerLoadeddata($event)"
    @timeupdate="onPlayerTimeupdate($event)"
    @canplay="onPlayerCanplay($event)"
    @canplaythrough="onPlayerCanplaythrough($event)"
    @statechanged="playerStateChanged($event)"
    @ready="playerReadied"
  >
  </video-player>
</template>

<script>
// import "video.js/dist/video-js.css";

import { videoPlayer } from "vue-video-player";
// import "videojs-youtube"

export default {
  name: "vod-video-player",
  props: {
    options: Object,
  },
  data() {
    return {};
  },
  components: {
    videoPlayer,
  },
  mounted() {
    console.log("this is current player instance object", this.player);
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // ...player event

    onPlayerEnded(player) {
      console.log("player ended!", player);
    },

    onPlayerWaiting(player) {
      console.log("player waiting!", player);
    },

    onPlayerPlaying(player) {
      console.log("player playng!", player);
    },



    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },

    // player has loaded data
    onPlayerLoadeddata(player) {
      console.log("the player has loaded data", player);
    },

    // player has loaded data
    onPlayerTimeupdate(player) {
      console.log("the player has updated time", player);
    },

    // player can play
    onPlayerCanplay(player) {
      console.log("the player can play", player);
    },

    // player can play through
    onPlayerCanplaythrough(player) {
      console.log("the player can play through", player);
    },
  },
};
</script>

<style lang="scss">
.video-player-box {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
</style>
