<template>
  <div
    class="slider-slide"
    :data-position="position"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "vod-slider-item",
  props: {
    position: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="scss">
.slider-slide {
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
  transition-delay: 0.1s;
  transition-property: transform;

  &[data-position="start"] {
    transform-origin: left bottom;
  }

  &[data-position="end"] {
    transform-origin: right bottom;
  }

  &[data-position="center"] {
    transform-origin: center bottom;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.5);
      z-index: 2;
    }
  }
}
</style>
