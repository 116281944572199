<template>
  <div class="title-card">
    <a
      role="link"
      :aria-label="title.post_title"
      tabindex="0"
      aria-hidden="false"
      class="slider-refocus"
      @click.prevent="handleClick"
    >
      <div class="boxart-size-16x9 boxart-container boxart-rounded">
        <img
          v-if="title.thumbnail"
          class="boxart-image boxart-image-in-padded-container"
          :src="title.thumbnail"
          :alt="title.post_title"
        />
        <div class="fallback-text-container" aria-hidden="true">
          <p class="fallback-text">{{ title.post_title }}</p>
        </div>
      </div>
      <div class="infos">
        <!-- <div class="row-infos-icons">
          <div class="row-infos-icons-start">
            <span class="icon icon-play">
              <font-awesome-icon :icon="icons.play" />
            </span>
            <span class="icon icon-add">
              <font-awesome-icon :icon="icons.plus" />
            </span>
          </div>
          <div class="row-infos-icons-end">
            <span class="icon icon-more" @click="showMoreHandle">
              <font-awesome-icon :icon="icons.arrowDown" />
            </span>
          </div>
        </div> -->
        <div class="row-infos-title">
          <h3 class="title-info title-name">{{ title.post_title }}</h3>
          <p>
            <span class="title-info title-ratings" v-if="title.ratings">{{
              title.ratings.toUpperCase()
            }}</span>
            <span class="title-info" v-if="title.duration">{{
              title.duration
            }}</span>
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPlus,
  faAngleDown,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "vod-title-card",
  props: {
    title: Object,
    handler: Function
  },
  data() {
    return {
      icons: {
        plus: faPlus,
        arrowDown: faAngleDown,
        play: faPlayCircle,
      },
    };
  },
  components: {
    // FontAwesomeIcon,
  },
  methods: {
    handleClick() {
      this.$emit('titleClick', this.title['ID'])
      // this.handler && this.handler(this.title)
    }
  },
};
</script>

<style lang="scss" scoped>
.title-card {
  position: relative;
  z-index: 1;

  &:not(.loadingTitle) {
    display: block;
  }

  &:focus {
    outline: 0;
  }

  &.is-open {
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }

  &.is-dimmed .boxart-container {
    opacity: 0.6;
  }
}

.boxart-rounded {
  border-radius: 4px;
}

.boxart-rounded .fallback-text-container {
  border-radius: 4px;
}

.boxart-size-16x9 {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  padding: 28.125% 0;
}

.boxart-size-1x2 {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  padding: 200% 0 0;
}

.boxart-size-7x10 {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
  padding: 35.714285714% 0;
}

.boxart-size-7x10 .boxart-image-in-padded-container {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 50%;
}

.boxart-size-7x10 .top-10-rank {
  position: absolute;
  top: 0;
  bottom: 0;
  right: auto;
  left: 0;
  width: 50%;
}

.boxart-size-7x10 .fallback-text-container {
  width: 50%;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxart-size-7x10 .fallback-text {
  font-size: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin: 5%;
  padding: 0;
  position: initial;
  white-space: normal;
}

.fallback-text-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0), #000);
  background-color: #222;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;
}

.fallback-text {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 8%;
  right: 8%;
  margin: 0;
  padding: 0 0 10%;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boxart-image-in-padded-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.infos {
  display: block;
  width: 100%;
  // height: 58px;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #000;
  transition: opacity 0.5s ease-in 0.3s;
  transform: translate3d(0, 98%, 0);
}

.row-infos-icons {
  display: flex;
  justify-content: space-between;
  padding: 6px;

  .row-infos-icons-start {
    display: inline-flex;
  }

  .row-infos-icons-end {
    display: inline-flex;
  }
}

.row-infos-title {
  padding: 6px;

  p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 10px;
    line-height: 1;
  }
}

@media (hover: hover) {
  .title-card:hover {
    .boxart-rounded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .infos {
      visibility: visible;
      opacity: 1;
    }
  }
}
.icon:not(.icon-play) {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & + .icon {
    margin-left: 4px;
  }

  &:hover {
    border-color: #fff;
  }

  &.icon-add {
    font-size: 12px;
  }

  &.icon-more {
    font-size: 16px;
  }
}

.icon.icon-play {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  &:hover {
    color: lighten(#000, 50%);
  }
}

.title-info {
  & + .title-info {
    margin-left: 4px;
  }

  &.title-name {
    width: 100%;
    display: block;
    line-height: 1.5;
    margin: 0 0 5px 0;
    font-size: 10px;
    color: #fff;
  }
}
.title-ratings {
  border: 1px solid #fff;
  line-height: 1;
  background-color: #000;
  padding: 1px 2px;
}

.title-card .slider-refocus:focus {
  outline: 0;
}
</style>
