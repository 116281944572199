<template>
  <div class="vod-hero">
    <div class="vod-hero-image-wrapper">
      <img
        v-if="title.thumbnail"
        class="hero static-image image-layer"
        :src="title.thumbnail"
        :alt="title.post_title"
      />
      <div class="hero-vignette vignette-layer"></div>
    </div>
    <div class="vod-hero-info">
      <h1 v-if="title.post_title" class="info-title">{{ title.post_title }}</h1>
      <p v-if="hero.subtitle" class="info-metas">{{ hero.subtitle }}</p>
      <p v-if="title.post_excerpt" class="info-description truncate">
        {{ title.post_excerpt }}
      </p>
      <p v-if="title" class="info-cta">
        <button class="info-more-button" @click.prevent="showMoreHandle">
          <font-awesome-icon :icon="icons.play" /><span class="info-more-button-text">Play Now</span></button
        >
        <!-- <button class="info-more-button" @click.prevent="showMoreHandle">
          More info
        </button> -->
      </p>
    </div>
    <vod-title-card-more
      :title="title"
      :visible="showMore"
      @modalChange="modalChange"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "vod-hero",
  props: {
    hero: Object,
    title: Object,
  },
  data() {
    return {
      showMore: false,
      icons: {
        play: faPlayCircle,
      },
    };
  },
  methods: {
    showMoreHandle: function () {
      this.showMore = !this.showMore;
    },
    modalChange: function (isVisible) {
      this.showMore = isVisible;
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss">
.vod-hero {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  user-select: none;
  touch-action: pan-y;
  margin-bottom: 20px;
  background-color: var(--e-global-color-background, #000);
  width: 100%;
  height: 56.25vw;
  z-index: 0;
  overflow: hidden;

  @media screen and (max-width: 1023px) {
    height: 56.25vh;
  }

  .vod-hero-image-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .hero-vignette {
      background-image: linear-gradient(
        to bottom,
        rgba(20, 20, 20, 0) 0,
        rgba(20, 20, 20, 0.15) 15%,
        rgba(20, 20, 20, 0.35) 29%,
        rgba(20, 20, 20, 0.58) 44%,
        #000000 68%,
        #000000 100%
      );
      background-size: 100% 100%;
      background-position: 0 top;
      background-repeat: repeat-x;
      background-color: transparent;
      width: 100%;
      height: 14.7vw;
      top: auto;
      bottom: -1px;
      opacity: 1;
    }

    .vignette-layer {
      z-index: 8;
      position: absolute;
      left: 0;
      right: 0;
    }

    .static-image {
      position: absolute;
      background-position: center center;
      background-size: cover;
      object-fit: cover;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s;
    }

    .episode-play-icon {
      position: absolute;
      width: 24px;
      height: 24px;
      font-size: 24px;
      color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .vod-hero-info {
    position: absolute;
    top: 25%;
    left: 4%;
    width: 36%;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color: #fff;

    @media screen and (max-width: 499px) {
      top: 15%;
      width: 72%;
    }

    & > p {
      line-height: 1.5;
    }

    .info-title,
    .info-metas {
      margin: 0 0 1rem 0;
    }

    .info-cta {
      display: flex;
    }

    .info-title,
    .info-metas,
    .info-description {
      color: white;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    }

    .info-description.truncate {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media screen and (max-width: 499px) {
        -webkit-line-clamp: 2;
      }
    }

    .info-more-button {
      background-color: rgba(109, 109, 110, 0.7);
      color: white;
      margin-right: 1rem;
      align-items: center;
      appearance: none;
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      opacity: 1;
      padding: 0.8rem;
      position: relative;
      user-select: none;
      will-change: background-color, color;
      word-break: break-word;
      white-space: nowrap;
      text-decoration: none;

      svg + .info-more-button-text {
        margin-left: 8px;
      }

      &:hover {
        background-color: rgba(109, 109, 110, 0.4);
      }

      &:active {
        background-color: rgba(109, 109, 110, 0.4);
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>
