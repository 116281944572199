<template>
  <div class="vod-carousel vod-carousel-title-card">
    <div class="row-container">
      <div class="row-content">
        <vod-slider
          :totalItems="totalItems"
          :itemsPerRow="itemsPerRow"
          :currentMedia="currentMedia"
        >
          <vod-slider-item
            v-for="(item, index) in items"
            :key="index"
            :position="getItemPosition(index)"
          >
            <vod-title-card :title="item" @titleClick="showMoreHandle" />
          </vod-slider-item>
        </vod-slider>
        <vod-title-card-more
          :title="selectedTitle"
          :visible="showMore"
          @modalChange="modalChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

const mediaQueries = [
  { type: "mini", mediaQuery: "(max-width: 499px)" },
  { type: "mobile", mediaQuery: "(min-width: 500px) and (max-width: 768px)" },
  { type: "tablet", mediaQuery: "(min-width: 769px) and (max-width: 1023px)" },
  {
    type: "desktop",
    mediaQuery: "(min-width: 1024px) and (max-width: 1215px)",
  },
  {
    type: "widescreen",
    mediaQuery: "(min-width: 1216px) and (max-width: 1407px)",
  },
  { type: "fullhd", mediaQuery: "(min-width: 1408px)" },
];

const carouselElements = {
  mini: 2,
  mobile: 3,
  tablet: 4,
  desktop: 5,
  widescreen: 5,
  fullhd: 6,
};

export default {
  name: "vod-carousel",
  props: {
    carouselTitle: String,
    items: Array,
  },
  data() {
    return {
      screen: null,
      itemsPerRow: 0,
      totalItems: 0,
      showMore: false,
      selectedTitle: {},
    };
  },
  created() {
    this.widthChange();
    window.addEventListener("resize", _.debounce(this.widthChange, 100));
  },
  mounted() {
    this.totalItems = this.items.length;
    this.itemsPerRow = this.getItemsPerRow();
  },
  destroyed() {
    window.removeEventListener("resize", _.debounce(this.widthChange, 100));
  },
  computed: {
    currentMedia() {
      return this.screen.type;
    },
  },
  methods: {
    showMoreHandle: function (id) {
      id && (this.selectedTitle =  this.items.find(el => id === el.ID))
      this.showMore = !this.showMore;
    },
    modalChange: function (isVisible) {
      this.showMore = isVisible;
      this.selectedTitle = {};
    },
    getItemPosition: function (i) {
      let current = i;
      let endPos = i + 1;
      let pos = "center";
      if (current % carouselElements[this.screen.type] == 0) {
        pos = "start";
      }
      if (endPos % carouselElements[this.screen.type] == 0) {
        pos = "end";
      }
      return pos;
    },
    widthChange() {
      if (matchMedia) {
        let sizes = mediaQueries.filter((item) => {
          return window.matchMedia(item.mediaQuery).matches;
        });
        this.screen = sizes.length ? sizes[0] : null;
        this.itemsPerRow = this.getItemsPerRow();
      }
    },
    getItemsPerRow() {
      return carouselElements[this.screen.type];
    },
  },
};
</script>

<style lang="scss">
a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}

section[id^="vod-carousel-"],
div[id^="vod-carousel-"] {
  overflow: hidden;
  padding-bottom: 48px;

  > {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .entry-title,
    .widget-title,
    .show-support h6,
    label {
      color: var(--e-global-color-primary, white);
    }
  }
}

.vod-carousel {
  position: relative;
  outline: 0;
}

.vod-carousel.vod-carousel-title-card {
  margin: 3vw 0;
  padding: 0;
  box-sizing: border-box;
}

.vod-carousel.vod-carousel-title-card .rowTitle {
  font-size: 1.4vw;
  color: #000;
  font-weight: 700;
  margin: 0 4% 0.5em 4%;
  text-decoration: none;
  display: inline-block;
  min-width: 6em;
}

.row-header {
  line-height: 1.3;
  margin: 0;
}

.row-container {
  position: relative;
  z-index: 0;
}

.row-content {
  padding: 0;
  box-sizing: border-box;
}

.row-container .row-content .slider {
  z-index: 2;
}
</style>
