<template>
  <vod-title-card-modal :visible="isVisible" @modalChange="modalChange">
    <div class="title-boxart-wrapper">
      <img
        v-if="title.thumbnail"
        :src="title.thumbnail"
        class="title-boxart-image"
        :alt="title.post_title"
      />
    </div>
    <div class="title-modal-container">
      <h3 class="title-boxart-name">{{ title.post_title }}</h3>
      <p class="title-metadatas">
        <span
          v-if="this.title.release_date"
          class="title-metadata title-release"
          >{{ this.title.release_date }}</span
        >
        <span v-if="title.ratings" class="title-metadata title-ratings">{{
          title.ratings.toUpperCase()
        }}</span>
        <span v-if="title.duration" class="title-metadata title-duration">{{
          title.duration
        }}</span>
      </p>
      <p>
        {{ title.post_excerpt }}
      </p>
      <p v-if="title.artists" class="title-artists">
        <strong>Artists:</strong> {{ title.artists }}
      </p>
      <template v-if="loading">Loading...</template>
      <div class="title-episodes" v-if="activeCollection">
        <div class="title-seasons">
          <div class="title-seasons-heading">
            <div class="title-seasons-heading-start">
              <p class="title-seasons-title">Videos</p>
            </div>
            <div class="title-seasons-heading-end">
              <select
                v-if="collections && collections.length > 1"
                class="title-season-select"
                @change="changeActiveCollection($event)"
              >
                <option
                  v-for="(item, index) in collections"
                  :key="index"
                  :value="index"
                  :selected="item.name === activeCollection.name"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <ul class="title-episodes-list" v-if="activeCollection.items">
            <li
              v-for="(episode, index) in activeCollection.items"
              :key="index"
              @click.prevent="handleEpisodeClick(episode.url)"
              class="title-episode"
            >
              <div class="episode">
                <div class="episode-start">
                  <figure class="episode-artbox">
                    <img :src="episode.thumbnail" alt="" />
                    <span class="episode-play-icon"
                      ><font-awesome-icon :icon="icons.play"
                    /></span>
                  </figure>
                </div>
                <div class="episode-metadatas">
                  <div class="episode-metadata-start">
                    <p class="episode-metadata-title">
                      {{ episode.post_title }}
                      <small
                        v-if="episode.duration"
                        class="episode-metadata-duration"
                      >
                        ({{ episode.duration }})
                      </small>
                    </p>
                  </div>
                  <div class="episode-metadata-end">
                    <p>{{ excerpt(episode.post_excerpt) }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </vod-title-card-modal>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "vod-title-card-more",
  props: {
    title: Object,
    visible: Boolean,
  },
  data() {
    return {
      loading: false,
      collections: [],
      activeCollection: null,
      selectedEpisode: null,
      showEpisode: false,
      icons: {
        play: faPlayCircle,
      },
    };
  },
  rendered() {
    this.activeCollection =
      this.title.collections.length && this.title.collections[0];
  },
  watch: {
    // whenever question changes, this function will run
    title: function (newColl) {
      if (newColl) {
        this.loading = true;
        fetch(`/wp-json/vod-suite/v1/playlists/${newColl.ID}/collections`)
          .then((stream) => stream.json())
          .then((data) => {
            this.collections = data;
            this.activeCollection = data[0];
          })
          .catch((error) => console.error(error));
        this.loading = false;
      }
      // this.activeCollection =
      //   newColl &&
      //   newColl.collections &&
      //   newColl.collections.length &&
      //   newColl.collections[0];
    },
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (isVisible) {
        this.$emit("modalChange", isVisible);
      },
    },
  },
  methods: {
    modalChange: function (isVisible) {
      this.isVisible = isVisible;
    },
    changeActiveCollection(event) {
      this.activeCollection = this.collections[event.target.value];
    },
    handleEpisodeClick(url) {
      window.location.href = url;
    },
    excerpt(text) {
      return text.length > 100 ? text.substring(0, 100) + " ..." : text;
    },
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style lang="scss">
.title-boxart-wrapper {
  height: 100%;
  width: 100%;
  padding-top: 56.3925%;
  position: relative;

  .title-boxart-image {
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.title-metadatas {
  margin: 16px 0;
  font-size: 14px;

  .title-metadata {
    & + .title-metadata {
      margin-left: 5px;
    }
  }

  .title-ratings {
    line-height: 1;
    padding: 2px;
    border: 1px solid #fff;
    box-sizing: border-box;
    font-weight: bold;
  }
}

.title-artists {
  font-size: 14px;
}

.title-modal-container {
  padding: 16px;
}

.title-boxart-name {
  font-size: 24px;
  line-height: 1.5;
  color: #fff;
  margin: 16px 0;
}

.title-seasons-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title-seasons-heading-start {
  flex: 0 0 50%;
}

.title-seasons-heading-end {
  flex: 1 0 50%;
  width: 50%;
  padding-left: 16px;
  text-align: right;
}

.title-seasons-title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.title-season-select {
  height: 40px;
  padding: 10px 20px 10px 10px;
  color: white;
  background-color: #181818;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  max-width: 100%;

  &:focus {
    background-color: #181818;
  }
}

.title-episodes-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.title-episode {
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #616161;
}

@media screen and (max-width: 499px) {
  .title-episode-number {
    display: none;
  }
}

.episode {
  display: flex;
  position: relative;

  @media screen and (max-width: 499px) {
    flex-direction: column;
  }

  .episode-start {
    display: flex;
    align-items: center;
  }

  .episode-artbox {
    width: 250px;
    margin: 0 16px 0 0;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .episode-play-icon {
      position: absolute;
      display: flex;
      width: 48px;
      height: 48px;
      font-size: 48px;
      color: white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 499px) {
      width: 100%;
      margin: 15px auto;
    }
  }

  .episode-metadatas {
    display: flex;
    flex-direction: column;
  }

  .episode-metadata-title {
    margin-top: 0;
    font-size: 18px;
    line-height: 1.333333;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
