<template>
  <div class="title-card-container">
    <vod-title-card :title="title"></vod-title-card>
  </div>
</template>

<script>
export default {
  name: 'vod-title-card-container',
  props: {
    title: Object,
  }
}
</script>

<style lang="scss"></style>
