<template>
  <b-modal
    v-model="isVisible"
    modal-tall
    centered
    size="lg"
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-bg-variant="footerBgVariant"
    :footer-text-variant="footerTextVariant"
    :hide-header="true"
    :hide-footer="true"
  >
    <template v-slot:default="{ close }">

      <slot></slot>

      <span class="icon icon-close modal-close-button" @click="close()">x</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";

export default {
  name: "vod-title-card-modal",
  directives: {
    "b-modal": VBModal,
  },
  props: {
    title: Object,
    visible: Boolean,
  },
  data() {
    return {
      headerBgVariant: "dark",
      headerTextVariant: "light",
      bodyBgVariant: "dark",
      bodyTextVariant: "light",
      footerBgVariant: "dark",
      footerTextVariant: "light",
    };
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (isVisible) {
        this.$emit("modalChange", isVisible);
      },
    },
  },
  components: {
    "b-modal": BModal,
  },
  methods: {},
};
</script>

<style lang="scss">
$dark: #000000;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/utilities/background";
@import "node_modules/bootstrap/scss/utilities/text";

@import "node_modules/bootstrap-vue/src/variables";
@import "node_modules/bootstrap-vue/src/utilities";
@import "node_modules/bootstrap-vue/src/components/modal/index";
@import "node_modules/bootstrap-vue/src/icons";

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  padding: 12px;
  margin: 12px;
  width: 16px;
  height: 16px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  &:hover {
    background-color: lighten(#000, 10%);
  }
}

.modal-body[class] {
  padding: 0;
  margin: 0;
}

.modal-content[class] {
  border: 1px solid transparent;
  background-color: transparent;
}
</style>
