// export { default as StandardComponent } from './StandardComponent'
// export { default as FunctionalComponent } from './FunctionalComponent'

export { default as Carousel } from "./Carousel";
export { Slider, SliderItem } from "./Slider";
export {
  TitleCardContainer,
  TitleCard,
  TitleCardMore,
  TitleCardModal
} from "./TitleCard";
export { default as VideoPlayer } from "./VideoPlayer";
export { default as Hero } from "./Hero";
export { SingleVideo, Youtube } from "./SingleVideo";
