<template>
  <div class="slider-container" ref="swiperEl">
    <div class="slider-wrapper">
      <slot></slot>
    </div>
    <div class="slider-pagination" ref="paginationEl"></div>
    <div class="slider-button-prev" ref="prevEl"></div>
    <div class="slider-button-next" ref="nextEl"></div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);


export default {
  name: "vod-slider",
  props: {
    totalItems: Number,
    itemsPerRow: Number,
    currentMedia: String,
  },
  data() {
    return {
      swiperRef: null,
    };
  },
  mounted() {
    this.swiperRef = new Swiper(this.$refs.swiperEl, {
      // Optional parameters
      // direction: 'horizontal',
      slidesPerView: this.itemsPerRow,
      slidesPerGroup: this.itemsPerRow,
      spaceBetween: 2,
      loop: false,

      // If we need pagination
      pagination: {
        el: this.$refs.paginationEl,
        // dynamicBullets: true,
        bulletClass: 'slider-pagination-bullet',
        bulletActiveClass: 'slider-pagination-bullet-active',
        modifierClass: 'slider-pagination-',
        currentClass: 'slider-pagination-current',
        totalClass: 'slider-pagination-total',
        hiddenClass: 'slider-pagination-hidden',
        progressbarFillClass: 'slider-pagination-progressbar-fill',
        clickableClass: 'slider-pagination-clickable',
        lockClass: 'slider-pagination-lock',
        progressbarOppositeClass: 'slider-pagination-progressbar-opposite',
      },

      // Navigation arrows
      navigation: {
        nextEl: this.$refs.nextEl,
        prevEl: this.$refs.prevEl,
        disabledClass: 'slider-button-disabled',
        hiddenClass: 'slider-button-hidden',
        lockClass: 'slider-button-lock',
      },

      breakpoints: {
        499: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 4
        },
        768: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 4
        },
        1023: {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 8
        },
        1407: {
          slidesPerView: 6,
          slidesPerGroup: 6,
          spaceBetween: 8
        }
      },

      containerModifierClass: 'slider-container-',
      slideClass: 'slider-slide',
      slideActiveClass: 'slider-slide-active',
      slideDuplicateActiveClass: 'slider-slide-duplicate-active',
      slideVisibleClass: 'slider-slide-visible',
      slideDuplicateClass: 'slider-slide-duplicate',
      slideNextClass: 'slider-slide-next',
      slideDuplicateNextClass: 'slider-slide-duplicate-next',
      slidePrevClass: 'slider-slide-prev',
      slideDuplicatePrevClass: 'slider-slide-duplicate-prev',
      slideBlankClass: 'slider-slide-invisible-blank',
      wrapperClass: 'slider-wrapper',

      // And if we need scrollbar
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },
    });
  },
  destroyed() {
    if (this.swiperRef && !this.swiperRef.destroyed) {
      this.swiperRef.destroy();
    }
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
$themeColor: #fff;
$colors: (
  'white': #ffffff,
  'black': #000000,
) !default;

@import "./swiper.scss";
@import "./navigation.scss";
@import "./pagination.scss";

.slider-container {
  & .slider-button-next,
  & .slider-button-prev {
    display: none;
  }
  @media (hover: hover) {
    & .slider-pagination {
      display: none;
    }
    &:hover .slider-button-next,
    &:hover .slider-button-prev {
      display: flex;
    }

    &:hover .slider-pagination {
      display: block;
    }
  }
}
</style>
