<template>
  <vod-title-card-modal :visible="isVisible" @modalChange="modalChange">
    <div v-if="video">
      <!-- <div
        v-if="video.media.oembed"
        v-html="video.media.oembed"
        class="embed-container"
      /> -->
      <!-- <div ref="youtube" @playing="playing"/> -->
      <div class="embed-container">
        <vod-youtube
          :video-id="video.video_id"
          :player-vars="{ autoplay }"
          ref="youtube"
          @playing="playing"
          @ended="playNext"
        ></vod-youtube>
      </div>
      <div class="title-modal-container">
        <!-- Intro area -->
        <section class="intro-area">
          <ul class="title-area">
            <!-- <li>
              <h3 class="tagline">VIKINGS S1 E1</h3>
            </li> -->
            <li>
              <h1 class="title">{{ video.post_title }}</h1>
            </li>
            <li>
              <h4 class="datas">
                <div class="metadatas metadata-year" v-if="video.release">
                  {{ video.release }}
                </div>
                &nbsp;&nbsp;
                <div class="metadatas metadata-duration" v-if="video.duration">
                  {{ video.duration }}
                </div>
              </h4>
            </li>
          </ul>
          <!-- <div class="line-break"> <br> </div> -->
          <!-- <div class="play-area">
            <button class="button a-play"><a href="..." style="text-decoration:none;"><h3><i class="fas fa-play" style="color:white"></i>PLAY</h3></a></button>
            <div class="fav">
              <button class="button b-save"><a href="#"><i class="far fa-bookmark" style="color:white; font-size:1.5em "></i></a></button>
              <button class="button b-save"><a href="#"><i class="far fa-share-square" style="color:white; font-size:1.5em; padding-right:5vw;"></i></a></button>
            </div>
          </div> -->
          <!-- <div class="line-break"><br /></div> -->
          <!-- <div class="description-area">
          <div class="description"><p>{{ video.post_excerpt }}</p></div>
          <div class="info" v-if="video.artists"><p>Artists:</p>&nbsp;&nbsp;<p class="metadatas metadata-director">{{ video.artists }}</p></div>
        </div> -->
        </section>
        <!-- End intro area -->
      </div>
    </div>
  </vod-title-card-modal>
</template>

<script>
// selectedEpOptions() {
//   return (
//     this.video && {
//       width: 640,
//       autoplay: "play",
//       sources: [
//         {
//           type: `video/${this.video.media.type}`,
//           src: this.video.media.source,
//         },
//       ],
//       poster: this.video.thumbnail,
//     }
//   );
// },

export default {
  name: "vod-single-video",
  props: {
    video: Object,
    prev: Object,
    next: Object,
    visible: Boolean,
    autoplay: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    isVisible: {
      get: function () {
        return this.visible;
      },
      set: function (isVisible) {
        this.$emit("modalChange", isVisible);
      },
    },
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    playing() {
      console.log("\\o/ we are watching!!!");
    },
    playNext() {
      this.next.url &&
        (window.location.href = `${this.next.url}?autoplay=true`);
    },
    modalChange: function (isVisible) {
      this.isVisible = isVisible;
    },
  },
};
</script>
